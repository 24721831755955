/* global SD, GeoPoint, SDGeocode, map */
import * as React from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { useState, useRef, useEffect } from 'react'
import { NumberChip as Chip } from '../components/Chip';
import Button from '@mui/material/Button';
import Menu from '../components/Menu'
import { navigate } from "gatsby"
import { useTheme } from "@mui/material";
import { styled } from '@mui/material/styles';
import Layout from "../components/layout";
import Autocomplete from '@mui/material/Autocomplete';
import Editor from "../components/Editor"
import Map from "../components/SDMap";
import Ad from '../components/Ad'

const Container = styled(Box)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "center",
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up("lg")]: {
        flexDirection: 'column',
    }
}));

const NotSupported = styled(Box)(({ theme }) => ({
    '@media (min-width: 0px)': {
        display: 'block'
    },
    '@media (min-width: 1200px)': {
        display: 'none'
    }
}));




function parseQuery(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}



const IndexPage = ({ data, location }) => {
    const params = parseQuery(location.search)
    const hideLogo = params.hideLogo;
    const divRef = useRef(null)
    const [query, setQuery] = useState("")
    const [geocode, setGeocode] = useState({
        lat: 103.80733282853,
        lng: 1.289478890541
    })
    useEffect(() => {
        window.set_data = (json) => {
            if (json[0]) {
                setGeocode({
                    lat: json[0].x,
                    lng: json[0].y,
                })
            }
        }
    }, [])

    const findGeo = (q) => {

        var geocode = new SDGeocode(map);
        var searchOption = { "q": q };
        var gc = SDGeocode.SG;
        geocode.requestData(gc, searchOption)
    }

    return (
        <Layout fullWidth={true}>
            
            <Container>
            <Ad />
            </Container>
            <Container alignItems={"start"} justifyContent={"start"}>
            <Box display="flex" flexDirection="row" mb="16px">
                <TextField id="outlined-basic" label="Postal Code" variant="outlined"
                    onChange={e => setQuery(e.target.value)}
                    value={query}
                />
                <Button
                    size="small"
                    onClick={() => findGeo(query)}
                >Find</Button>

            </Box>
            </Container>
            <Container>

                <Editor>
                    <Map
                        centerGeocode={geocode}
                    />
                </Editor>

                <NotSupported>
                    <h2> This feature is only available using desktop. You can try our other features.</h2>
                    <Menu />
                </NotSupported>
            </Container ></Layout>)
}

export default IndexPage

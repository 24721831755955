/* global SD, GeoPoint, SDGeocode, map */
import * as React from "react";
import Box from '@mui/material/Box';
import { useState } from 'react'
import { NumberChip as Chip } from '../components/Chip';

const { useEffect, useRef } = React;

const LENGTH = 1200

var winOject = (typeof window !== 'undefined') ? window : {}


const IndexPage = ({ data, centerGeocode }) => {
    const divRef = useRef(null)
    const loadMap = () => {

        const latlng = new GeoPoint(centerGeocode.lat, centerGeocode.lng);
        const myOptions = {
            zoom: 13,
            center: latlng,
            showCopyright: false
        };

        winOject.map = new SD.genmap.Map(
            divRef.current,
            myOptions
        );


    }

    useEffect(() => {
        if (divRef.current) {
            loadMap()
        }
    }, [])

    useEffect(() => {
        if(centerGeocode) {
            var latlng = new GeoPoint(centerGeocode.lat, centerGeocode.lng);
            winOject?.map.setCenter(latlng, map.zoom)
        }
    }, [centerGeocode])


    return (
        <Box style={{ position: 'relative', width: `${LENGTH}px`, height: `${LENGTH}px`, }}>
            <div ref={divRef} style={{ width: `100%`, height: `100%` }}></div>
        </Box>)
}

export default IndexPage
